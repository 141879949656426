import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledLogo = styled(Img)`
  width: 150px;
  margin: 0px auto 40px;
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return <StyledLogo fluid={data.file.childImageSharp.fluid} />
}

const FooterArea = styled.div`
  margin: 20px;
  font-size: 1.4rem;
  p {
    margin: 0;
    font-weight: 300;
  }

  a {
    color: rgb(0, 96, 126);
    font-weight: 300;
  }
`
const StyledFooter = styled.footer`
  width: 100%;
  background-color: rgb(240, 240, 240);
  color: rgb(0, 96, 126);
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
`

const Footer = () => (
  <StyledFooter>
    <Logo />
    <FooterArea>
      <p>662-936-583</p>
      <p>biuro@albamet.pl</p>
      <p>ul. Okrężna 11, 42-360 Poraj</p>
    </FooterArea>

    <FooterArea>
      <p>NIP: 5742017932</p>
      <p>REGON: 384980653</p>
    </FooterArea>

    <FooterArea>
      <a href="/Klauzula-informacyjna-RODO.pdf">Polityka Prywatności</a>
    </FooterArea>
  </StyledFooter>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
