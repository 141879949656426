import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"
import device from "../deviceQueries"

const StyledAppWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 8px;

    @media ${device.mobileL} {
      font-size: 10px;
    }

    @media ${device.laptop} {
      font-size: 12px;
    }

    @media ${device.laptopL} {
      font-size: 14px;
    }

    @media ${device.desktop} {
      font-size: 15px;
    }
  }
  body {
    background-color: rgb(249,249,249);
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width:100vw;
    max-width:100vw;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *{
    box-sizing: border-box; 
  }


`

const Layout = ({ children }) => {
  return (
    <StyledAppWrapper>
      <Header />

      <main style={{ overflowX: "hidden" }}>{children}</main>
      <Footer />
      <GlobalStyle />
    </StyledAppWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
