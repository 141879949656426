import React, { useState } from "react"
import styled, { css } from "styled-components"
import device from "../../deviceQueries"
import { withPrefix } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import MenuIcon from "../styledContainers/menuIcon"

const List = styled.ul`
  list-style: none;
  text-decoration: none;
  padding: 0;
  display: none;

  @media ${device.laptop} {
    display: inline-flex;
  }
`

const Item = styled.li`
  margin: 5px;
  padding: 10px 30px;
  outline: 0;
  cursor: pointer;

  a,
  button {
    outline: 0;
    text-decoration: none;
    color: white;
    font-weight: 300;
    font-size: 2rem;
    border: none;
    background-color: transparent;

    @media ${device.tablet} {
      font-size: 1.4rem;
    }

    @media ${device.laptop} {
      font-size: 1.6rem;
    }

    @media ${device.laptopL} {
      font-size: 2rem;
    }
  }

  ${props =>
    props.green &&
    css`
      background-color: rgb(249, 249, 33);
      border-radius: 5px;
      a {
        color: black;
      }
    `}
`

const StyledMobileList = styled.ul`
  position: fixed;
  z-index: -2;
  top: -25px;
  left: 0;
  width: 100vw;
  height: calc(100vh + 25px);
  background: rgb(56, 133, 161);
  background-size: 110% 100%;
  transform: translateX(100%);
  transition: transform 0.5s ease, opacity 0.5s ease;

  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  opacity: 0;
  @media ${device.laptop} {
    display: none;
  }

  ${props =>
    props.opened &&
    css`
      transform: translateX(0%);
      opacity: 1;
    `}
`

const NavButtons = () => {
  const [opened, toggleMenu] = useState(false)

  return (
    <nav>
      <MenuIcon opened={opened} onClick={() => toggleMenu(!opened)} />
      <List>
        <Item onClick={() => scrollTo("#offer")}>
          <a>Oferta</a>
        </Item>
        <Item>
          <a target="_blank" href="https://allegro.pl/uzytkownik/albamet">
            Kup teraz
          </a>
        </Item>
        <Item onClick={() => scrollTo("#contact")}>
          <a>Kontakt</a>
        </Item>
        <Item green>
          <a target="_blank" href={withPrefix("/albamet_katalog.pdf")}>
            Katalog
          </a>
        </Item>
      </List>
      <StyledMobileList opened={opened}>
        <Item
          onClick={() => {
            scrollTo("#offer")
            toggleMenu(false)
          }}
        >
          <a>Oferta</a>
        </Item>
        <Item>
          <a target="_blank" href="https://allegro.pl/uzytkownik/albamet">
            Kup teraz
          </a>
        </Item>
        <Item
          onClick={() => {
            scrollTo("#contact")
            toggleMenu(false)
          }}
        >
          <a>Kontakt</a>
        </Item>
        <Item green>
          <a target="_blank" href={withPrefix("/albamet_katalog.pdf")}>
            Katalog
          </a>
        </Item>
      </StyledMobileList>
    </nav>
  )
}

export default NavButtons
