import React, { useRef, useEffect } from "react"
import Logo from "../../assets/logo_vect.svg"
import { gsap } from "gsap"

const LogoWhite = () => {
  const logoRef = useRef()
  useEffect(() => {
    const paths = logoRef.current.children[0].children[1].querySelectorAll(
      "path"
    )
    const tl = gsap.timeline({
      delay: 1,
    })
    tl.to(logoRef.current, { opacity: 1, duration: 0.3 })
      .fromTo(
        paths,
        {
          strokeDasharray: 500,
          strokeDashoffset: 500,
          fill: "rgba(255,255,255,0.01)",
        },
        {
          strokeDashoffset: 0,
          duration: 5,
        },
        0
      )
      .to(paths, { fill: "rgba(255,255,255,1)", strokeWidth: 0, delay: 1.4 }, 0)
  }, [])

  return (
    <div ref={logoRef} style={{ opacity: 0 }}>
      <Logo />
    </div>
  )
}

export default LogoWhite
