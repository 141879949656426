import React from "react"
import styled, { css } from "styled-components"
import device from "../../deviceQueries"

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    display: none;
  }
`

const IconContent = styled.div`
  width: 30px;
  height: 3px;

  :after,
  :before {
    width: 30px;
    height: 3px;
  }

  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(255, 255, 255, 1);
  transition: all 0ms 300ms;

  :before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    background: rgba(255, 255, 255, 1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  :after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    background: rgba(255, 255, 255, 1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  ${props =>
    props.animate &&
    css`
      background: rgba(255, 255, 255, 0);

      :after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
          transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      :before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
          transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      }
    `}
`

const Icon = ({ opened, onClick }) => (
  <IconWrapper onClick={onClick}>
    <IconContent animate={opened} />
  </IconWrapper>
)

export default Icon
