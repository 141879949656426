import React from "react"
import styled from "styled-components"
import device from "../deviceQueries"
import LogoWhite from "./header/logoWhite"
import NavButtons from "./header/navButtons"

const LogoContainer = styled.div`
  width: 160px;

  @media ${device.mobileL} {
    width: 200px;
  }

  @media ${device.laptop} {
    width: 230px;
  }

  @media ${device.laptopL} {
    width: 260px;
  }

  @media ${device.desktop} {
    width: 300px;
  }
`

const StyledHeader = styled.header`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;

  @media ${device.tablet} {
    padding: 10px 3%;
  }

  @media ${device.laptop} {
    padding: 20px 8%;
  }

  @media ${device.laptopL} {
    padding: 25px 15%;
  }

  @media ${device.desktop} {
    padding: 30px 25%;
  }
`

const Header = () => (
  <StyledHeader>
    <LogoContainer>
      <LogoWhite />
    </LogoContainer>
    <NavButtons />
  </StyledHeader>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
